// src/ProjectPage.js
import React from "react";
import "./StartPNC.scss"; 
import pic from "../assets/images/selfie.jpeg";
import annotated from "../assets/images/Internship.pdf";

const StartPNC = () => {
  return (
    <div className="project-page-container">
      <h1 className="project-title">Start PNC</h1>

      {/* First Text Blurb */}
      <div className="project-blurb">
        <p>
        &emsp;The Start PNC app was inspired by the realization that there was a gap in financial education tools designed specifically for youth. Observing the lack of engaging and educational resources for children aged 6-18 in PNC’s offerings, our team of interns envisioned a mobile app that would teach kids the importance of saving and financial concepts in a fun and interactive way. This project aimed to bridge the educational gap, making financial literacy accessible and enjoyable for younger audiences.
< br />
< br />
&emsp;As the sole developer, I lead the creation of this prototype during a ten-week summer internship. My role involved translating our team’s research and design ideas into a functional app. We decided to focus on children aged 6-12, based on market research and competitive analysis, and named the app "Start PNC." The app features a user-friendly interface, designed collaboratively with UX/UI interns, to engage young users and facilitate learning through interactive elements and gamification.
<br/>
<br/>
&emsp;The prototype was presented to the Digital Team with the aim of future adoption by PNC. This project showcased my ability to integrate technical development with creative and educational design, resulting in a comprehensive tool that addresses a crucial need in financial education for youth.
        </p>
      <div className = "link">
        <a href="https://youtube.com/shorts/aPnEpr2_rrg?si=BNHV8swlM-eUvBv1" target="_blank" rel="noopener noreferrer">
              View Demo Video
            </a>
            </div>
      </div>

      {/* First Image */}
      <div className="project-image">
        <img
          src={pic}
          alt="Project Screenshot 1"
          className="image"
        />
      </div>

      {/* Second Text Blurb */}
      <div className="project-blurb-columns">
  <div className="column">
    <h3>Features:</h3>
    <h4>Home</h4>
    <ul>
      <li>Top level view of accounts and tasks</li>
      <li>Tips from Artie (StartPNC's mascot)</li>
    </ul>
    <h4>Wallet</h4>
    <ul>
      <li>Child's "checking" account</li>
      <li>Can request money from parents, send money to their "savings", or share their pay link</li>
      <li>View recent activity and spending</li>
    </ul>
    <h4>Savings</h4>
    <ul>
      <li>Transfer funds in and out of the "savings" sub account</li>
      <li>Create a wishlist and transfer money to that item</li>
    </ul>
    <h4>Tasks</h4>
    <ul>
      <li>See tasks that were sent from parents</li>
      <li>Mark task as complete and wait for parent approval</li>
    </ul>
    <h4>Progress</h4>
    <ul>
      <li>Earn points for completing goals</li>
    </ul>
  </div>

  <div className="column">
    <h3>Tech Stack:</h3>
    <h4>Sketch</h4>
    <ul>
      <li>Building wireframes and screen design</li>
    </ul>
    <h4>InVision</h4>
    <ul>
      <li>Creating the prototype to ensure intuitive user flow before implementation</li>
    </ul>
    <h4>Swift</h4>
    <ul>
      <li>Implementing the app for iOS users</li>
    </ul>
  </div>
</div>

      {/* Links to PDFs */}
      <div className="project-pdfs">
        <h3>Related Documents:</h3>
        <ul>
          <li>
            <a href={annotated} target="_blank" rel="noopener noreferrer">
              Capstone Essay
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StartPNC;
