// src/pages/PasswordProtected.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PasswordProtection.scss'; // Import the CSS file

const PasswordProtected = ({ correctPassword }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      navigate('/projects/startPNC');
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="password-protected-container">
      <h2>This page is password protected</h2>
      <p>Please enter password or contact me at atd43@pitt.edu to obtain it.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default PasswordProtected;

