import React from 'react';
import './Contact.scss'; // Adjust the path as necessary
import ContactForm from './ContactForm'; // Assuming you have a ContactForm component
import Top from "../containers/topbutton/Top.js";

function Contact() {
  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <div className="contact-container">
        <div className="contact-form">
          <ContactForm />
        </div>
      </div>
      <Top/> 
    </div>
  );
}

export default Contact;

