// src/ProjectPage.js
import React from "react";
import "./Scraps.scss"; 
import pic from "../assets/images/scrapswireframe.jpeg";
import annotated from "../assets/images/Annotated.pdf";
import DNID from "../assets/images/DNID.pdf";

const Scraps = () => {
  return (
    <div className="project-page-container">
      <h1 className="project-title">Scraps</h1>

      {/* First Text Blurb */}
      <div className="project-blurb">
        <p>
        &emsp;My inspiration for Scraps stemmed from realizing how social media networks have evolved away from the fun and creative elements that initially drew me to them. Instead of fostering creativity and genuine connections, they've become focused on popularity-driven content. This led me to envision a digital "scrapbook" where friends can authentically share their experiences, fostering a community that values creativity, living in the moment, and embracing individuality over superficial popularity and perfection. I began developing this idea and finally brought it to life for my DNID Capstone in December 2023. Scraps remains an ongoing passion project that I continue to work on in my free time.
< br />
< br />
&emsp;By eliminating features like public likes and comments, and allowing only unedited, real-time posts directly from the camera, Scraps pushes users to have genuine interactions free from comparison and false realities. Users are identified by a unique signature color rather than a profile picture, enhancing personalization and creativity. The platform encourages positive connections through curated interest-based tags and offers a customizable profile scrapbook.
        </p>
      <div className = "link">
        <a href="https://youtu.be/KpFIQlu5Ju4" target="_blank" rel="noopener noreferrer">
              View Demo Video
            </a>
            </div>
      </div>

      {/* First Image */}
      <div className="project-image">
        <img
          src={pic}
          alt="Project Screenshot 1"
          className="image"
        />
      </div>

      {/* Second Text Blurb */}
      <div className="project-blurb-columns">
  <div className="column">
    <h3>Features:</h3>
    <h4>Home</h4>
    <ul>
      <li>View your friends' posts</li>
      <li>Support a friends' post by reacting with emojis or sending a message</li>
    </ul>
    <h4>Explore</h4>
    <ul>
      <li>Find friends by searching by name</li>
      <li>Search for communities with the same interest by looking through categories</li>
    </ul>
    <h4>Post</h4>
    <ul>
      <li>Take and post images to the feed</li>
      <li>No ability to import images from camera roll</li>
    </ul>
    <h4>Messages</h4>
    <ul>
      <li>View friends' reactions to your post</li>
      <li>Message friends</li>
    </ul>
    <h4>Profile</h4>
    <ul>
      <li>Customize your personal scrapbook, add stickers, rearrange photos, and create albums</li>
    </ul>
  </div>

  <div className="column">
    <h3>Tech Stack:</h3>
    <h4>Sketch</h4>
    <ul>
      <li>Building wireframes and screen design</li>
    </ul>
    <h4>Javascript</h4>
    <ul>
      <li>Programming the app</li>
    </ul>
    <h4>React Native</h4>
    <ul>
      <li>Building a streamlined platform for mobile and web</li>
      <li>Build features native to iOS and Android</li>
    </ul>
    <h4>Firebase</h4>
    <ul>
      <li>Handle accounts and login verification</li>
      <li>Hold user data and images</li>
    </ul>
  </div>
</div>

      {/* Links to PDFs */}
      <div className="project-pdfs">
        <h3>Related Documents:</h3>
        <ul>
          <li>
            <a href={annotated} target="_blank" rel="noopener noreferrer">
              Research
            </a>
          </li>
          <li>
            <a href={DNID} target="_blank" rel="noopener noreferrer">
              Final Essay
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Scraps;
