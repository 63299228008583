import React from "react";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import WorkExperience from "./workExperience/WorkExperience";
import Footer from "../components/footer/Footer";
import Talks from "./talks/Talks";
import Education from "./education/Education";
import ScrollToTopButton from "./topbutton/Top";
import "./Main.scss";

const Main = () => {
  return (
    <>
      <Greeting />
      <Education />
      <WorkExperience />
      <Skills />
      <Talks />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Main;
