import React from "react";
import "./Projects.scss";
import Top from "../containers/topbutton/Top.js";
import Scraps from "../assets/images/scraps.png";
import js from "../assets/images/javascript.png"
import react from "../assets/images/react.png"
import firebase from "../assets/images/firebase.jpeg"
import sketch from "../assets/images/sketch.png"
import start from "../assets/images/start.png"
import swift from "../assets/images/swift.jpeg"
import bike from "../assets/images/bike.jpeg"
import python from "../assets/images/python.png"
import jupyter from "../assets/images/jupyter.png"
import code from "../assets/images/code.png"
import sass from "../assets/images/sass.png"

import { Helmet } from 'react-helmet';

export default function Projects() {
  const projectData = [
    {
      id: 1,
      title: "Scraps",
      description: "Scraps is an app I individually designed and implemented as a part of my DNID capstone. It is aimed at creating a mentally healthy social media platform.",
      image: Scraps,
      learnMoreLink: "/project-1",
      githubLink: "https://github.com/username/project1",
      buttons: [
        { text: "Learn More", link: "/projects/scraps", type: "primary" },
      ],
      icons: [
        { src: js, alt: "Icon 1" },
        { src: react, alt: "Icon 2" },
        { src: firebase, alt: "Icon 2" },
        { src: sketch, alt: "Icon 2" }
      ]
    },
    {
      id: 2,
      title: "StartPNC",
      description: "During my time at PNC Bank, the five interns within the Digital group created an app to introduce younger generations to the importance of financial literacy.",
      image: start,
      learnMoreLink: "/project-2",
      githubLink: "https://github.com/username/project2",
      buttons: [
        { text: "Learn More", link: "/projects/startPNC-password", type: "primary" }
      ],
      icons: [
        { src: swift, alt: "Icon 3" },
        { src: sketch, alt: "Icon 3" }
      ]
    },
    {
      id: 3,
      title: "Bike Pittsburgh Analysis",
      description: "Bike Pittsburgh Analysis is a data analysis project using Python and some SQL to explore 2021 bike rental data from HealthyRidePGH. See bikes_pgh_data.ipynb for implementation",
      image: bike,
      githubLink: "https://github.com/username/project2",
      buttons: [
        { text: "GitHub", link: "https://github.com/alexisdinardo/FinalProject", type: "github" }
      ],
      icons: [
        { src: python, alt: "Icon 4" },
        { src: jupyter, alt: "Icon 4" },
      ]
    },
    {
      id: 4,
      title: "This Website!",
      description: "I created this website from a one page template on github (click below to see template). From there, I added my own project pages, implemented the form handling on the contact page, and added a few of my own animations all in React Javascript.",
      image: code,
      githubLink: "https://github.com/username/project2",
      buttons: [
        { text: "Template", link: "https://github.com/saadpasta/developerFolio", type: "github" }
      ],
      icons: [
        { src: js, alt: "Icon 4" },
        { src: react, alt: "Icon 4" },
        { src: sass, alt: "Icon 4" },
      ]
    },
  ];

  return (
    <div className="projects" id="projects">
            <Helmet>
        <title>Alexis DiNardo</title>
      </Helmet>
      <h1>Projects</h1>
      <div className="projects-container">
        {projectData.map((project) => (
          <div key={project.id} className="project-card project-card-light">
            {project.image && (
              <div className="project-image">
                <img
                  src={project.image}
                  alt={project.title}
                  className="card-image"
                />
              </div>
            )}
            <div className="project-detail">
              <h5 className="card-title">{project.title}</h5>
              <p className="card-subtitle">{project.description}</p>
              <div className="project-icons">
                {project.icons?.map((icon, index) => (
                  <img
                    key={index}
                    src={icon.src}
                    alt={icon.alt}
                    className="project-icon"
                    style={{ width: '50px', height: '50px', margin: '0 8px'}} 
                  />
                ))}
              </div>
              <div className="project-card-footer">
                {project.buttons?.map((button, index) => (
                  <a
                    key={index}
                    href={button.link}
                    className={`project-tag ${button.type}`}
                    target={button.type === "github" ? "_blank" : "_self"}
                    rel={button.type === "github" ? "noopener noreferrer" : undefined}
                  >
                    {button.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Top /> {/* Add the Top button component here */}
    </div>
  );
}
