import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import Main from "./containers/Main";
import Projects from "./assets/Projects";
import Scraps from "./assets/Scraps"
import PasswordProtected from './assets/PasswordProtected';
import StartPNC from "./assets/StartPNC"
import "./assets/Projects.scss"
import Header from "./components/header/Header"; // Import the Navbar component
import ScrollToTop from "./components/ScrollToTop";
import Contact from "./assets/Contact"

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensure ScrollToTop is included here */}
      <div>
        <Header /> {/* Navbar will be displayed on every page */}
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/scraps" element={<Scraps />} /> 
          <Route path="/projects/startPNC-password" element={<PasswordProtected correctPassword="start!23" />} />
          <Route path="/projects/startPNC" element={<StartPNC />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
